import React from 'react';

import Story from './components/story';
import { Link } from '../../components';

export default function Stampfeet() {
  /* eslint-disable max-len */
  const props = {
    name: 'Stampfeet',
    title:
      'How Stampfeet increased customer engagement by 20% by connecting payment cards to its loyalty platform',
    subtitle: 'With Asaf Rozin, CEO and co-founder at Stampfeet',
    seoTitle:
      'How Stampfeet increased customer engagement by 20% by connecting payment cards to its loyalty platform',
    heroSquare: {
      background: '#23745b',
      content: (
        <div>
          <h2>The Goal</h2>
          <p>
            Create an easy and fast way for merchants to launch loyalty
            programs, while boosting end user engagement and spend.
          </p>
          <h2>The Results</h2>
          <ul>
            <li>
              366% increase in number of merchants added in the past year - up
              5x from the previous year
            </li>
            <li>
              41% average increase in number of transactions processed each
              month
            </li>
          </ul>
        </div>
      ),
    },
    heroImgAlt: '',
    keyFigures: {
      Website: <Link to="http://stampfeet.com">stampfeet.com</Link>,
      Region: 'US & UK',
      Industry: 'Loyalty',
      Products: 'Transactions API',
    },
    companyElement: (
      <>
        <h2>Challenge</h2>
        <p>
          To make it easy and fast for merchants to launch loyalty programs and
          create a simple, frictionless way for end users to earn rewards.
        </p>
        <h2>Solution</h2>
        <p>
          Using Fidel API’s Select Transactions API, Stampfeet provides a way
          for merchants to launch loyalty programs within days, with no need for
          integrations and regardless of POS system. All while creating seamless
          end user experiences that increase engagement and keep customers
          returning.
        </p>
      </>
    ),
    challengeTitle: 'A Growing Opportunity',
    challengeElement: (
      <>
        <div className="less-letter-spacing">
          <h3>
            CEO and co-founder of Stampfeet, Asaf Rozin, began to notice a
            growing opportunity.
          </h3>
          <p>
            Stampfeet is a customer loyalty platform that works across all
            aspects of loyalty, from initial strategy planning, all the way
            through to technical implementation. However, Asaf early on saw
            opportunities to grow and expand Stampfeet’s offerings, starting
            with the way their customers spent and earned rewards.
          </p>
          <p>
            To improve the overall customer experience on his platform, Asaf
            knew he needed to start with how customers check-out. He sought to
            reward customers instantaneously for everyday spend however, he
            quickly realised retailers needed to integrate their Point of Sale
            (POS) systems to Stampfeet. This proved to be a challenge as many of
            the retail brands Stampfeet worked with had tens, even hundreds, of
            retail stores. Many retailers used different POS systems, meaning
            they would have to be integrated and maintained separately - a
            massive logistical feat and pressure on resources for both Stampfeet
            and its retail partners
          </p>
          <p>
            Another difficulty of various POS systems is that it makes it
            difficult to track payment data in a meaningful way that drives
            customer insights. Any data that was being picked up and tracked by
            the POS systems was typically siloed, making it difficult to build a
            more comprehensive and omnichannel view of customer spend behaviour.
          </p>
          <p>
            Lastly, and arguably most importantly, was that the checkout
            experience for customers was a multi-step process if they wanted to
            earn rewards for their spending. Receipt data had to be combined
            with a customer loyalty number, meaning customers had to show a
            loyalty card or scan a QR code at checkout. Asaf knew there must be
            a way to create a seamless process for Stampfeet users to earn
            rewards while they shop, without any additional friction at
            checkout.
          </p>
        </div>

        <div>
          <h2 className="text-left">The Solution | Smooth Sailing</h2>
          <p>
            Stampfeet offers a simple solution for merchants to set up loyalty
            programs within a few days, with no integrations and regardless of
            POS system. With Fidel API acting as a connectivity layer, merchants
            working with Stampfeet can create easy, scalable and frictionless
            loyalty platforms by connecting payment cards to loyalty apps or
            programs.
          </p>
          <p>
            By enabling loyalty programs that work at almost any merchant and
            POS system, Stampfeet’s loyalty programs are more scalable. In
            addition, Stampfeet and it’s merchant partners can better understand
            spend behaviour with real-time payment data. Tracking spend data is
            simple with Fidel API’s Select Transactions API and SDKs which
            connect user payment cards to loyalty programs or apps within
            seconds. With secure and tokenized spend data, Stampfeet and it’s
            merchant partners can better understand spend patterns and create
            more personalised end user experiences.
          </p>
          <p>
            With just a few clicks, end users can connect payment cards to
            loyalty programs and automatically earn reward points while they
            spend. Plus, users can opt-in to receive real-time notifications of
            points and rewards earned. This means no more slow checkout
            processes where users have to scan plastic loyalty cards, QR codes,
            or pull up an app on their phone. Instead, users shop and pay
            normally with their cards and automatically earn rewards without any
            added friction.
          </p>
        </div>
      </>
    ),
    solutionTitle: 'The Results | Making Strides',
    solutionElement: (
      <div>
        <p>
          Asaf and his team have been happy to see positive results since
          partnering with Fidel API in 2018.{' '}
          <strong>
            In the past year, Stampfeet has seen a 366% increase in the number
            of merchant locations added to their programs, up 5x that from the
            previous year (2019 vs 2020).
          </strong>
        </p>
        <p>
          In addition,{' '}
          <strong>
            on average Stampfeet has seen a 41% increase in number of
            transactions month-on-month
          </strong>{' '}
          (Jan2018-March2021) and{' '}
          <strong>
            an average of 211% increase in number of cards linked month-on-month
          </strong>{' '}
          (Jan2018-March2021).
        </p>
        <p>
          What sets Stampfeet apart? Stampfeet’s ability to support omnichannel
          operations out-of-the-box is a huge benefit in a fragmented market.
          Traditional operations usually only cater to one channel (either
          online or offline) and require specific integrations for each. Asaf
          differentiates Stampfeet from the competition by offering both online
          and offline payment data through a single API. All while making it
          easy and fast for merchants to create loyalty programs that drive
          retention.
        </p>
        <p>
          Stampfeet has its sights set on expanding and improving end user
          experiences across all of its programs. But what exactly Asaf has up
          his sleeve, we’ll have to wait and see!
        </p>
      </div>
    ),
    highlight:
      '“Thanks to our integration with Fidel API, we can now offer merchants and retailers an easy route to launch state of the art loyalty programs without any integration on their side.”',
    highlightImgAlt: '',
    quoteAuthor: {
      name: 'Asaf Rozin',
      description: 'CEO and co-founder of Stampfeet',
      avatar: 'asaf_rozin',
    },
  };
  /* eslint-enable */

  return <Story {...props} />;
}
